import Image from 'next/image'
import Link from 'next/link'

import linkResolver from '@lib/prismic/link-resolver'
import type { ProductCard as ProductCardType } from '@lib/types'

import RichTextSerializer from '@components/RichTextSerializer'
import ProductOrCollectionCard from '@components/products/ProductOrCollectionCard'

interface Props {
	slice: any
}

export default function FancyProductsSlider({ slice }: Props) {
	const { primary, items } = slice

	return (
		<section className="overflow-x-hidden section">
			<div className="relative">
				{/* Colored background */}
				<div
					className="absolute top-0 left-0 h-[120px] lg:h-[240px] right-20 sm:right-1/4 bg-bbx-glow -z-10"
					aria-hidden="true"
				/>

				{/* Gray background */}
				<div
					className="absolute bg-gray-150 top-[120px] lg:top-[239px] md:h-[300px] lg:h-[400px] xl:h-[500px] h-2/3 right-0 w-1/2 -z-10"
					aria-hidden="true"
				/>

				<div className="container flex flex-col sm:relative pt-14 lg:pt-28">
					<div className="max-w-[50%]">
						{/* Title */}
						{primary?.section_title && (
							<div className="title text-3xl font-medium xs:text-4xl lg:text-7xl text-white">
								<RichTextSerializer content={primary.section_title} />
							</div>
						)}

						{/* Description */}
						{primary?.section_description && (
							<div className="mt-4">
								<RichTextSerializer content={primary.section_description} />
							</div>
						)}
					</div>

					{/* Image */}
					{primary?.section_image?.url && (
						<div className="absolute w-1/2 sm:right-0 -right-4 sm:w-5/12 xl:w-auto top-16 lg:top-28 -z-10">
							<Image
								src={primary.section_image.url}
								width={primary.section_image.dimensions.width}
								height={primary.section_image.dimensions.height}
								alt={primary.section_image.alt}
								objectFit="contain"
								quality={100}
							/>
						</div>
					)}

					{/* Products grid */}
					{Array.isArray(items) && items.length > 0 && (
						<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:pr-32 lg:pr-0 max-w-[840px] mt-12 lg:mt-20 gap-x-6 lg:gap-x-6 lg:gap-y-10 gap-y-6">
							{items.map((product: ProductCardType) => (
								<ProductOrCollectionCard
									image={product.image}
									price={product.price}
									vendor={product.vendor}
									handle={product.handle}
									key={product.id}
									id={product.id}
									name={product.name}
									avgRating={product.avgRating}
									numReviews={product.numReviews}
									hideFavoriteButton
									collections={product.collections}
									hasStock={product.hasStock}
								/>
							))}
						</div>
					)}

					{/* Section CTA */}
					{primary?.section_cta && primary?.section_cta_label && (
						<Link href={linkResolver(primary.section_cta)}>
							<a className="self-center mt-16 button button-secondary">
								{primary.section_cta_label}
							</a>
						</Link>
					)}
				</div>
			</div>
		</section>
	)
}
